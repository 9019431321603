@charset "UTF-8";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Allgemeine Regeln                                                                                                  */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  line-height: 1.231;
  color: #34495e;
}

h1 {
  color: #66b0c2;
}

section {
  margin-bottom: 15px;
}

#app {
  min-height: 100%;
  position: relative;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Splash screen                                                                                                      */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.splash {
  text-align: center;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Form                                                                                                            */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.form-inline select {
  margin-right: 10px;
}

.form-inline input {
  margin-right: 10px;
}

.form-inline {
  margin-bottom: 10px;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Bootstrap customization                                                                                                               */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.navbar-default {
  background-color: #66b0c2;
}

.navbar-default .navbar-brand {
  color: #fff;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #66b0c2;
  border-color: #007a92;
}

.panel-primary {
  border-color: #66b0c2;
}

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #66b0c2;
  border-color: #66b0c2;
}

a {
  color: #66b0c2;
  text-decoration: none;
}

.navbar {
  border-radius: 0px;
}

.label {
  font-size: 100%;
}

.content {
  padding-bottom: 260px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  background-color: #f5f5f5;
}

.form-control-feedback {
  text-align: start;
  cursor: pointer;
  pointer-events: auto;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Helper Classes                                                                                                            */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.pointer {
  cursor: pointer;
}

.mt-0 {
  margin-top: 0px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-7 {
  margin-bottom: 7px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.pt-15 {
  padding-top: 15px;
}

.red {
  color: red;
}

.kof-color {
  color: #66b0c2;
}

.grey-bg {
  background-color: #f9f9f9;
}

.grey {
  color: #999;
}

.preformatted {
  white-space: pre-wrap;
}

@media (min-width: 768px) {
  .no-pad-sm {
    padding-left: 0px;
    padding-right: 0px;
  }

  .no-pr {
    padding-right: 0px;
  }

  .no-pl {
    padding-left: 0px;
  }
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Ranking Table                                                                                                      */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.vintage_warning {
  font-size: 25px;
  color: #e84d4d;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.weightingInfo {
  font-size: 20px;
  color: #999;
  user-select: none;
  cursor: default;
}

.disclaimer {
  text-align: right;
  margin-top: 8px;
  color: #999999;
}

abbr[title] {
  cursor: default;
}

div.ui-tooltip {
  width: auto;
  max-width: 400px;
}

.ageRangeDisplay {
  font-weight: normal !important;
  font-size: 12px !important;
}

::-ms-clear {
  display: none; /* Hides the clear button in ms browsers cause it won't behave */
}

.columnFilterInput {
  height: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
}

select.columnFilterInput {
  padding: 0px;
}

.dataTable th {
  vertical-align: top;
}

#rankingtable {
  width: 100% !important;
}

#rankingtable thead {
  position: sticky;
  top: 0;
  background-color: white;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 30px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  box-shadow: 0 0 3px rgba(68, 68, 68, 0.38);
  background-color: rgba(24, 202, 64, 0.31);
  color: #34495e;
  text-indent: 0px !important;
  line-height: 15px !important;
  font-weight: bold;
}
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before {
  background-color: rgba(220, 49, 44, 0.31);
  bont-weight: bold;
}

.rankingNA {
  color: #b0bfce;
}

.rankingDescription {
  margin-right: 20px;
}

#ageSlider {
  margin-right: 10px;
  margin-top: 7px;
}

.dataTable hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.colWidthHack110 {
  width: 110px;
}

.colWidthHack70 {
  width: 70px;
}

.navbar-inverse {
  background-color: #eeeeee;
  color: #34495e;
  border: none;
  border-radius: 4px;
}

.navbar-inverse .navbar-nav > li > a {
  color: #34495e;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
  color: #64829a;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
  color: #64829a;
}

.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:focus,
.navbar-inverse .navbar-nav > .disabled > a:hover {
  color: #999999;
}

.navbar-inverse li .active,
.navbar-inverse li:focus {
  background-color: #e0e0e0 !important;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #e0e0e0;
}

.navbar-inverse .navbar-collapse {
  border: none;
}

.dataTables_wrapper .dataTables_processing {
  top: 120px;
  height: 100%;
}

/* Responsive details: right "align" */
.dtr-data {
  float: right;
}

.dtr-details {
  width: 90%;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* Version                                                                                                 */
/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

#version {
  margin-top: 10px;
}

#version > label {
  font-weight: normal;
  margin-right: 5px;
}
